import { createTheme, theme as defaultTheme } from '@kyc-ui/react'
import { ReactComponent as LogoWithText } from 'src/assets/images/KYC_Screen_Logo_White-purple.svg'
import { ReactComponent as LogoIcon } from 'src/assets/icons/kyc-logo.svg'
import Background from 'src/assets/images/main-bg.svg'
import loadingAnimation from 'src/assets/lottie-files/loading.json'

export const theme = () => {
  return createTheme(defaultTheme, {
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            color: '#FFFFFF'
          }
        }
      }
    },
    lotties: {
      loading: JSON.stringify(loadingAnimation)
    },
    images: {
      logo: LogoWithText,
      logoIcon: LogoIcon,
      background: Background
    },
    colorName: {
      button: 'secondary',
      linearProgress: 'secondary',
      checkbox: 'secondary'
    },
    colorString: {},
    others: {
      loginBackgroundSize: 'contain'
    }
  })
}

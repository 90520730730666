import { useState, useEffect } from 'react'
import { useMediaQuery } from '@kyc-ui/react'
import { useSelector } from 'src/store'
import useTheme from 'src/hooks/common/useTheme'
import AppAPI from 'src/api/app'

export type TCustomer = { id: number; name: string }

interface UseAPPReturn {
  apiUrl?: string
  error?: Error
  isMobile: boolean
  customers: TCustomer[]
}

export default function useApp(): UseAPPReturn {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const error = useSelector((state) => state.app.error)
  const [customers, setCustomers] = useState<TCustomer[]>([])

  useEffect(() => {
    if (!customers.length) {
      ;(async () => {
        const resp = await AppAPI.getSharedCustomers()
        const items: TCustomer[] = []
        Object.keys(resp).forEach((key) => {
          if (key !== '__method' && !isNaN(+key)) {
            items.push({
              id: +key,
              name: resp[+key]
            })
          }
        })
        setCustomers(items.sort((a, b) => a.name.localeCompare(b.name)))
      })()
    }
  }, [customers])

  return {
    error,
    isMobile,
    customers
  }
}

import React from 'react'
import { LinearProgress, styled } from '@kyc-ui/react'
import Loading from 'src/components/common/Loading'
import useTheme from 'src/hooks/common/useTheme'

const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999
})

export default function PageLoader() {
  const theme = useTheme()
  return (
    <LoaderWrapper>
      <LinearProgress color={theme.colorName?.linearProgress} />
      <Loading isGlobal={true} />
    </LoaderWrapper>
  )
}

import React, { useState, useCallback } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { Stack, Backdrop, Box, styled, Typography, Chip } from '@kyc-ui/react'
import Loading from 'src/components/common/Loading'
import useTranslation from 'src/hooks/common/useTranslation'
import useTheme from 'src/hooks/common/useTheme'
import BillingPortalForm from 'src/components/BillingPortalForm'

const UsageReport = () => {
  useMsalAuthentication(InteractionType.Redirect)
  const t = useTranslation('usageReport')
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [isUsageReport, setIsUsageReport] = useState(true)

  const handleLoading = useCallback((value: boolean) => setLoading(value), [])

  return (
    <>
      <AuthenticatedTemplate>
        <UsageReportBox data-testid="usage-report">
          <TitleBox data-testid="title">
            <Typography align="left" color={theme.palette.secondary.main} variant="body2">
              {t('name')}
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
              <Chip
                sx={{ px: 3, py: 2, height: 'auto', borderRadius: '28px' }}
                label={
                  <Typography align="left" variant="h5">
                    {t('title')}
                  </Typography>
                }
                color={isUsageReport ? 'primary' : 'default'}
                onClick={() => setIsUsageReport(!isUsageReport)}
              />
              <Chip
                sx={{ px: 3, py: 2, height: 'auto', borderRadius: '28px' }}
                label={
                  <Typography align="left" variant="h5">
                    {t('renew')}
                  </Typography>
                }
                color={!isUsageReport ? 'primary' : 'default'}
                onClick={() => setIsUsageReport(!isUsageReport)}
              />
            </Stack>
          </TitleBox>
          <BillingPortalForm isUsageReport={isUsageReport} onLoading={handleLoading} />
          <Backdrop open={loading} style={{ flexDirection: 'column' }} data-testid="loading-backdrop">
            <Loading />
            <Typography color={theme.palette.common.white}>{t('loading')}</Typography>
          </Backdrop>
        </UsageReportBox>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UsageReportBox>
          <Typography sx={{ textAlign: 'center', m: '24px auto' }}>Please signin...</Typography>
        </UsageReportBox>
      </UnauthenticatedTemplate>
    </>
  )
}

const UsageReportBox = styled(Box)(({ theme }) => ({
  width: '100%',

  '& .MuiPaper-root': {
    boxShadow: 'none',
    display: 'grid',
    gap: theme.spacing(4)
  }
}))

const TitleBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(12),

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6)
  }
}))

export default UsageReport

import { createSlice } from '@reduxjs/toolkit'

export interface State {
  apiUrl?: string
  frontendHostName?: string
  isError?: boolean
  error?: Error
}

const initialState = {} as State

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {}
})

export default appSlice
export const appActions = appSlice.actions

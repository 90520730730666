import React from 'react'
import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@kyc-ui/react'
import { ExitToApp } from '@mui/icons-material'
import { useMsal, AuthenticatedTemplate } from '@azure/msal-react'
import Logo from 'src/components/layout/Logo'

export const Navbar: React.FC = () => {
  const { instance } = useMsal()
  const handleLogout = async () => {
    await instance.logout()
  }
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Box display="flex" flexGrow={1} sx={{ alignItems: 'center', pl: 2 }}>
          <Logo />
        </Box>
        <AuthenticatedTemplate>
          <Tooltip placement="left" title={'Logout'}>
            <IconButton aria-label="Logout" color="inherit" data-element="logout-button" onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </AuthenticatedTemplate>
      </Toolbar>
    </AppBar>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { App } from 'src/App'
import { store } from 'src/store'
import 'src/utils/i18n'

// For dev env
const devClientID = '8e1b5740-ddd0-4ea3-878c-18efd35f7a09'
const devTenantId = 'ea20bddf-416c-4aae-9566-eb045434a374'

// For prod env
const prodClientID = 'cdaaaab1-a751-4bb1-b019-b9e785295e5b'
const prodTenantId = 'ea20bddf-416c-4aae-9566-eb045434a374'

export const currentClientId = process.env.REACT_APP_ENV === 'PROD' ? prodClientID : devClientID

const configuration: Configuration = {
  auth: {
    clientId: currentClientId,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_ENV === 'PROD' ? prodTenantId : devTenantId}`
  }
}

const pca = new PublicClientApplication(configuration)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

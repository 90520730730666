import storage from 'store2'
import { API_SERVER_URL } from 'src/constants/storage'

function getAPIServerUrl(): string | undefined {
  return storage.session.get(API_SERVER_URL)
}

function setAPIServerUrl(apiServerUrl?: string) {
  storage.session.set(API_SERVER_URL, apiServerUrl)
}

function getLocalStorage(key: string) {
  return storage.get(key)
}

function setLocalStorage(key: string, value: unknown) {
  return storage.set(key, value)
}

const storageHelper = {
  getAPIServerUrl,
  setAPIServerUrl,
  getLocalStorage,
  setLocalStorage
}

export default storageHelper

import React, { Suspense } from 'react'
import { PageLoader, ThemeWrapper } from 'src/components/layout'
import { AppContainer } from 'src/components/layout/AppContainer'
import Snackbar from 'src/components/common/Snackbar'
import UsageReport from 'src/screens/UsageReport'

const App = () => (
  <ThemeWrapper>
    <Suspense fallback={<PageLoader />}>
      <AppContainer>
        <UsageReport />
      </AppContainer>
      <Snackbar />
    </Suspense>
  </ThemeWrapper>
)

export { App }

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'
import { merge, get, isBoolean, isString } from 'lodash'
import storage from 'src/utils/storage'
import { TOKEN } from 'src/constants/storage'
import { DEFAULT_TIMEOUT } from 'src/constants/network'
import { AuthError } from 'src/middlewares/errorHandler'

export function createInstance(config: AxiosRequestConfig): AxiosInstance {
  const instance = axios.create(
    merge(
      {},
      {
        baseURL: storage.getAPIServerUrl(),
        timeout: DEFAULT_TIMEOUT,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        paramsSerializer: (params: any) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      },
      config
    )
  )

  instance.interceptors.request.use(
    (paramConfig) => {
      paramConfig.params = {
        ...paramConfig.params
      }

      const token = Cookies.get(TOKEN)
      if (paramConfig.headers && token && paramConfig.headers?.authorization !== false) {
        paramConfig.headers.Authorization = `Bearer ${token}`
      }
      if (paramConfig.headers?.authorization === false) {
        // Upload file does not need authorization
        delete paramConfig.headers.authorization
      }

      return paramConfig
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (res) => {
      const innerConfig = res.config
      if (res.data && !isBoolean(res.data) && !isString(res.data)) {
        res.data.__method = innerConfig.method
      }

      return res
    },
    (error) => {
      if (get(error, 'response.status') === 401 || get(error, 'response.data.code') === 401) {
        return Promise.reject(new AuthError(error as string))
      }
      if (error.response && get(error.response, 'status') === 400) {
        return Promise.reject(new Error(get(error, 'response.data.Message')))
      }
      return Promise.reject(error)
    }
  )

  return instance
}

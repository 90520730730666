import React, { useMemo, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useMsalAuthentication, useAccount, useMsal } from '@azure/msal-react'
import { InteractionType, AccountInfo } from '@azure/msal-browser'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import * as yup from 'yup'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DesktopDateRangePicker, LocalizationProvider, MobileDateRangePicker } from '@mui/lab'
import { TextField } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import {
  Grid,
  Box,
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  useSnackbar
} from '@kyc-ui/react'
import useTranslation from 'src/hooks/common/useTranslation'
import useApp from 'src/hooks/app/useApp'
import useTheme from 'src/hooks/common/useTheme'
import { IUsageReportDownloadForm, TDownloadPayload, TRenewPayload } from 'src/typings/types'
import AppAPI from 'src/api/app'
import { TOKEN } from 'src/constants/storage'
import { currentClientId } from 'src/index'

const ENVIRONMENTS = [
  { id: 'PROD', name: 'PROD' },
  // { id: 'demo', name: 'DEMO' },
  // { id: 'uat', name: 'UAT' },
  // { id: 'stage', name: 'STAGE' },
  { id: 'DEV', name: 'DEV' }
]

const initialValues: IUsageReportDownloadForm = {
  environment: process.env.REACT_APP_ENV || 'DEV',
  customer: null,
  dateRange: [null, null]
}

interface IProps {
  isUsageReport?: boolean
  onDownloadReport?: () => void
  onDownloadRefinitivReport?: () => void
  onRenew?: () => void
  onLoading?: (value: boolean) => void
}

const BillingPortalForm = ({ isUsageReport = true, onLoading }: IProps) => {
  const { instance, accounts } = useMsal()
  const { addSnackbar } = useSnackbar()
  const account: AccountInfo | null = useAccount(accounts[0] || {})
  useMsalAuthentication(InteractionType.Redirect)
  const t = useTranslation('usageReport')
  const theme = useTheme()
  const { isMobile, customers } = useApp()
  const [customerSearchText, setCustomerSearchText] = useState('')

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: [`api://${currentClientId}/Read`],
          account
        })
        .then((response) => {
          Cookies.set(TOKEN, response.accessToken)
        })
    }
  }, [addSnackbar, instance, account])

  const validationSchema = useMemo(
    () =>
      yup.object({
        environment: yup.string().required(),
        dateRange: yup.array().of(yup.date()).required()
      }),
    []
  )

  const handleDownloadReport = async (isRefinitiv = false) => {
    const values = formik.values
    onLoading?.(true)
    if (account && formik) {
      const {
        customer,
        dateRange: [startDate, endDate]
      } = values
      try {
        const payload: TDownloadPayload = {
          from: dayjs(startDate).format('YYYY-MM-DD'),
          to: dayjs(endDate).format('YYYY-MM-DD'),
          email: account.username
        }
        if (!isRefinitiv) {
          payload.sharedCustomerIds = [customer!]
        }

        await AppAPI[isRefinitiv ? 'downloadRefinitivReport' : 'downloadReport'](payload)

        addSnackbar({
          message: 'Due to long processing times, you will receive the report via email.',
          alertTitle: 'Generating Report',
          severity: 'success',
          variant: 'standard',
          dismissAfter: 0,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        })
      } catch ({ message }) {
        addSnackbar({
          message: String(message) || 'Failed to download report, please try again later.',
          alertTitle: 'Generating Report',
          severity: 'error',
          variant: 'standard',
          dismissAfter: 0,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        })
        onLoading?.(false)
      }
    }
    onLoading?.(false)
  }

  const handleRenew = async () => {
    const values = formik.values
    onLoading?.(true)
    if (account && formik) {
      const {
        customer,
        dateRange: [startDate, endDate]
      } = values
      try {
        const payload: TRenewPayload = {
          sharedCustomerId: customer,
          renewFrom: dayjs(startDate).format('YYYY-MM-DD'),
          renewTo: dayjs(endDate).format('YYYY-MM-DD')
        }

        if (customer) {
          const res = await AppAPI.renew(payload)
          addSnackbar({
            message: res?.data || 'Renewed successfully.',
            alertTitle: 'Renew',
            severity: 'success',
            variant: 'standard',
            dismissAfter: 0,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          })
        }
      } catch (err: any) {
        addSnackbar({
          message: String(err?.response?.data || err?.message) || 'Failed to renew, please try again later.',
          alertTitle: 'Renew',
          severity: 'error',
          variant: 'standard',
          dismissAfter: 0,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        })
        onLoading?.(false)
      }
    }
    onLoading?.(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: () => void 0
  })

  const disabled = !formik.dirty || Object.keys(formik.errors).length > 0 || !formik.values.customer
  const refinitivDisabled = !formik.dirty || Object.keys(formik.errors).length > 0

  const DateRangePicker = isMobile ? MobileDateRangePicker : DesktopDateRangePicker

  return (
    <form>
      <Card sx={{ px: 4, py: 6 }}>
        <FormControl>
          <InputLabel id="environment">{t('environment')}</InputLabel>
          <Select
            fullWidth
            disabled
            id="environment"
            labelId="environment"
            name="environment"
            label={t('environment')}
            value={formik.values.environment}
            onChange={formik.handleChange}
          >
            {ENVIRONMENTS.map((env) => (
              <MenuItem key={env.id} value={env.id}>
                {env.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="customer">{t('customer')}</InputLabel>
          <Select
            fullWidth
            id="customer"
            labelId="customer"
            name="customer"
            label={t('customer')}
            value={formik.values.customer || ''}
            onChange={formik.handleChange}
          >
            <ListSubheader style={{ padding: '16px 16px 0' }}>
              <TextField
                size="small"
                autoFocus
                autoComplete="off"
                placeholder="Search"
                fullWidth
                onChange={(e) => setCustomerSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
              />
            </ListSubheader>
            {customers
              .filter((customer) => customer.name?.toLowerCase().includes(customerSearchText))
              .map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              inputFormat="dd/MM/yyyy"
              startText={t('startDate')}
              endText={t('endDate')}
              value={formik.values.dateRange}
              onChange={(value) => formik.setFieldValue('dateRange', value, true)}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    id="start-date"
                    {...startProps}
                    autoComplete="off"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <DateRangeIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: theme.spacing(4)
                      }
                    }}
                  />
                  <Box sx={{ mx: 4 }}> to </Box>
                  <TextField
                    id="end-date"
                    {...endProps}
                    autoComplete="off"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <DateRangeIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: theme.spacing(4)
                      }
                    }}
                  />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </FormControl>
        <Grid container spacing={4}>
          {isUsageReport ? (
            <>
              <Grid item xs={6}>
                <Button
                  id="download-button"
                  variant="contained"
                  fullWidth
                  disabled={disabled}
                  color={theme.colorName.button}
                  style={{ height: 40 }}
                  onClick={() => handleDownloadReport()}
                >
                  {t('download')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  id="download-refinitiv-button"
                  variant="contained"
                  fullWidth
                  disabled={refinitivDisabled}
                  color={theme.colorName.button}
                  style={{ height: 40 }}
                  onClick={() => handleDownloadReport(true)}
                >
                  {t('downloadRefinitivReport')}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button
                id="renew-button"
                variant="contained"
                fullWidth
                disabled={disabled}
                color={theme.colorName.button}
                style={{ height: 40 }}
                onClick={handleRenew}
              >
                {t('renew')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </form>
  )
}

export default BillingPortalForm

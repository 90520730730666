import { createInstance } from 'src/utils/fetch'
import { TDownloadPayload, TRenewPayload } from 'src/typings/types'

const fetch = createInstance({})

const billingApiUrl =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://billing.knowyourcustomer.com/'
    : 'https://billing-devstage.knowyourcustomer.com/'

const AppAPI = {
  async getSharedCustomers(): Promise<any> {
    const { data } = await fetch.get(`${billingApiUrl}customers`)
    return data
  },
  async downloadReport(payload: TDownloadPayload): Promise<any> {
    return await fetch.post(`${billingApiUrl}Billing/generate`, payload)
  },
  async downloadRefinitivReport(payload: TDownloadPayload): Promise<any> {
    return await fetch.post(`${billingApiUrl}Billing/refinitiv`, payload)
  },
  async renew(payload: TRenewPayload): Promise<any> {
    return await fetch.post(`${billingApiUrl}Customers/renew`, payload)
  }
}

export default AppAPI

import React from 'react'
import Lottie from 'react-lottie'
import { Modal, Box, styled } from '@kyc-ui/react'
import useTheme from 'src/hooks/common/useTheme'

interface Props {
  height?: number | string
  width?: number | string
  hide?: boolean
  isGlobal?: boolean
}

const GlobalBox = styled(Box)`
  .loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &.hide {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const Loading: React.FC<Props> = (props) => {
  const { hide = false, isGlobal = false, height = 64, width = 64 } = props
  const theme = useTheme()
  const LoadingBox = isGlobal ? GlobalBox : Box
  const loadingBox = () => (
    <LoadingBox
      id="loading-icon"
      tabIndex={-1}
      sx={{
        color: theme.palette.primary.main
      }}
    >
      {theme.lotties?.loading && (
        <Lottie
          height={height}
          options={{
            autoplay: true,
            animationData: JSON.parse(theme.lotties.loading),
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          width={width}
        />
      )}
    </LoadingBox>
  )

  return isGlobal ? <Modal open={!hide}>{loadingBox()}</Modal> : hide ? <></> : loadingBox()
}

export default Loading

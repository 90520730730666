import { useTranslation as useI18NTranslation } from 'react-i18next'
import { uniqBy } from 'lodash'
import { useCallback } from 'react'
import type { TErrorCode } from 'src/typings/types'

export default function useTranslation(keyPrefix = 'AppContainer.alertMessage') {
  const { t } = useI18NTranslation('translation', { keyPrefix })
  return t
}

export function useI18N() {
  const { i18n } = useI18NTranslation()
  return i18n
}

export function useBtnTranslation() {
  const { t: btnT } = useI18NTranslation('translation', { keyPrefix: 'AppContainer.btnText' })
  return btnT
}

export function useErrorMessageTranslation(keyPrefix = 'AppContainer.alertMessage') {
  const et = useTranslation(keyPrefix)

  return useCallback(
    (errorCodes: TErrorCode[]) => {
      errorCodes = uniqBy(errorCodes, 'Code')
      return errorCodes.map((error) => et(error.Code, error.Description)).join('\r\n')
    },
    [et]
  )
}

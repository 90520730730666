export const LANGUAGES: Record<string, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  jp: '日本語',
  zh: '中文'
}

export const FALLBACK_LANGUAGE = 'en'

import { Middleware } from '@reduxjs/toolkit'

export class AuthError extends Error {
  code: string

  constructor(message: string) {
    super(message)

    Error.captureStackTrace(this, AuthError)
    this.name = 'AuthError'
    this.code = '401'
  }
}

export class NetworkError extends Error {
  code: string

  constructor(message: string) {
    super(message)

    Error.captureStackTrace(this, NetworkError)
    this.name = 'NetworkError'
    this.code = '503'
  }
}

const errorHandler: Middleware = () => (next) => (action) => {
  if (action.error && action.error.name === 'AuthError') {
    if (window.location.href !== '/') {
      window.location.href = '/'
    }

    return
  }
  return next(action)
}

export default errorHandler

import React from 'react'
import { Box, Grid, styled } from '@kyc-ui/react'

import { Navbar } from './Navbar'

const PageWrapper = styled(Grid)(({ theme }) => ({
  flex: '1 1',
  margin: `${theme.spacing(34)} auto`,
  padding: `0 ${theme.spacing(4)}`,
  maxWidth: 1240,

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(24),
    padding: 0
  }
}))

export const AppContainer: React.FC = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      width="100%"
      sx={{
        bgcolor: (theme) => theme.palette.background.default
      }}
    >
      <Navbar />
      <PageWrapper container>{children}</PageWrapper>
    </Box>
  )
}

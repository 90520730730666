import React from 'react'
import useTheme from 'src/hooks/common/useTheme'

const Logo = () => {
  const theme = useTheme()

  return <theme.images.logo name="dashboardLogo" height={'32px'} />
}

export default Logo

import { createSelectorHook, useDispatch as _useDispatch } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import appSlice from 'src/state/app/slice'
import errorHandler from 'src/middlewares/errorHandler'

export const store = configureStore({
  reducer: {
    app: appSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {}
    }).concat(errorHandler)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useDispatch = () => _useDispatch<AppDispatch>()
export const useSelector = createSelectorHook<RootState>()
